<template>
  <div class="inner-page">
    <div class="wrapper">
      <h1 class="the-title">
        {{ title }}
      </h1>
      <div class="format-text">
        <p>
          {{ description }}
        </p>
        <template v-for="(content, index) in contents">
          <div :key="index">
            <h3>{{ content.name }}</h3>
            <ol>
              <li v-for="(item, i) in content.contents" :key="i">
                <p v-html="item"></p>
              </li>
            </ol>
          </div>
        </template>
      </div>
      <!-- end of format text -->
    </div>
    <!-- end of wrapper -->
  </div>
  <!-- end of inner page -->
</template>

<script>
export default {
  name: "TermAndCondition",
  metaInfo: {
    title: "Term And Conditions"
  },
  data() {
    return {
      title: null,
      description: null,
      contents: []
    };
  },
  mounted() {
    this.getContent();
  },
  methods: {
    getContent() {
      this.$axios
        .get("/pages/terms-and-conditions", {
          params: {
            lang: this.$store.state.locale.lang
          }
        })
        .then(response => {
          this.title = response.data.data.title;
          this.description = response.data.data.description;
          this.contents = response.data.data.contents;
        });
    }
  }
};
</script>
